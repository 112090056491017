/* eslint-disable no-debugger */
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import axios, { AxiosInstance } from 'axios';
import { getAuthDeferred } from '../utils/firebase';
import {
  Company,
  InviteInfo,
  User,
  Template,
  IDocument,
  GetAppsResponse,
  Room,
  InterviewResult,
  Recommendations,
  Prosody,
} from '@/interfaces/index';
import { WORKERS } from './config';
import qs from 'qs';
import { RoomExpired, RoomNotFound, RoomServerError } from '@/errors';
import { isPast } from 'date-fns';
import { processBucketedSeries } from '@/hooks/utils/emotions';

const { USERS_WORKER } = WORKERS;

class MongoClient {
  firebaseIdToken?: string;
  usersWorker: AxiosInstance;

  constructor() {
    this.usersWorker = this.createAxiosInstance(USERS_WORKER);
    const auth = getAuthDeferred();
    onAuthStateChanged(auth, async (user) => {
      const token = await user?.getIdToken();
      this.firebaseIdToken = token;

      // Refresh Axios instances with the new token
      if (this.firebaseIdToken) {
        this.updateAxiosInstancesWithToken();
      }
    });
  }

  createAxiosInstance(
    baseURL: string,
    useCustomInterceptor: boolean = true,
  ): AxiosInstance {
    const instance = axios.create({
      baseURL,
      headers: this.firebaseIdToken
        ? { Authorization: `Bearer ${this.firebaseIdToken}` }
        : {},
    });

    if (useCustomInterceptor) {
      instance.interceptors.response.use(undefined, this.createInterceptor());
    }
    return instance;
  }

  createInterceptor() {
    return async (error: any) => {
      if (error.response?.status === 401) {
        const auth = getAuthDeferred();
        const token = await auth.currentUser?.getIdToken(true);
        this.firebaseIdToken = token;
        if (this.firebaseIdToken) {
          this.updateAxiosInstancesWithToken();
          const config = {
            ...error.config,
            headers: {
              ...error.config.headers,
              Authorization: `Bearer ${this.firebaseIdToken}`,
            },
            _retry: true,
          };
          return axios(config);
        }
      }
      return Promise.reject(error);
    };
  }

  updateAxiosInstancesWithToken() {
    this.usersWorker.defaults.headers.Authorization = `Bearer ${this.firebaseIdToken}`;
    this.usersWorker.defaults.paramsSerializer = (params) => {
      return qs.stringify(params, { indices: false }); // param=value1&param=value2
    };
  }

  getProsody = async ({ roomId }: { roomId: string }) => {
    const { data: response } = await this.usersWorker.get<Prosody>(
      '/hume/prosody/' + roomId,
    );
    if (response.status === 'generating') {
      return response;
    } else if (response.status === 'generated') {
      const { data } = response;

      const { result, maximumTime } = processBucketedSeries(
        data.bucketedSeries,
        data.topSeries,
      );

      return {
        status: 'generated',
        data: {
          ...data,
          topSeries: data.topSeries,
          bucketedSeries: data.bucketedSeries,
          newBucketedSeries: [
            {
              ...result[0],
              time: 0,
            },
            ...result,
          ],
          duration: maximumTime,
        },
      } as Prosody;
    } else {
      return response;
    }
  };

  getSignedRecording = async ({ roomId }: { roomId: string }) => {
    const { data } = await this.usersWorker.post(
      '/recordings/signed-recording',
      {
        roomId,
      },
    );

    return data;
  };

  deleteRecording = async (roomId: string) => {
    const { data } = await this.usersWorker.post('/delete-recording', {
      roomId,
    });

    return data;
  };

  search = async (query: string) => {
    const { data: hits } = await this.usersWorker.get('/search/' + query);
    // make unique set values
    return new Set([...hits]);
  };

  getTranscription = async ({
    transcriptionId,
  }: {
    transcriptionId: string;
  }) => {
    const { data } = await this.usersWorker.get(
      '/recordings/transcription/' + transcriptionId,
    );
    return data;
  };

  redirectToPortal = async ({ customerId }: { customerId: string }) => {
    const { data } = await this.usersWorker.post('/redirect-to-portal', {
      customerId,
    });

    return data;
  };

  initBot = async ({ roomId }: { roomId: string }) => {
    const { data } = await this.usersWorker.post<{
      id: string;
      livekit_token: string;
    }>('/bot/init', {
      room_id: roomId,
    });

    return data;
  };

  requestLeave = async ({ roomId }: { roomId: string }) => {
    const { data } = await this.usersWorker.post('/bot/request-leave', {
      room_id: roomId,
    });

    return data;
  };

  upgradePlan = async () => {
    const { data } = await this.usersWorker.post(
      '/create-checkout-session',
      {},
    );

    return data;
  };

  sendFeedback = async ({
    feedback,
    roomId,
  }: {
    feedback: string;
    roomId: string;
  }) => {
    const { data } = await this.usersWorker.post('/users/feedback', {
      message: feedback,
      room_id: roomId,
    });

    return data;
  };

  getUser = async (token: string): Promise<User | null> => {
    const { data } = await this.usersWorker.get('/users/auth', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return data;
  };

  deleteJobApplicants = async (appIds: string[]) => {
    await this.usersWorker.delete('/applications/delete-applications', {
      data: {
        app_ids: appIds,
      },
    });
  };

  async getSub() {
    const { data } = await this.usersWorker.get(`/user/sub`);
    return data;
  }

  async fetchInterviewInfo(roomId: string) {
    const { data } = await this.usersWorker.get<{
      company: { name: string; profile_image: string };
      session_title: string;
    }>(`/sessions/${roomId}/info`);
    return data;
  }

  async validateUserField(field: string, value: string) {
    const { data } = await this.usersWorker.get(`/users/isValidField`, {
      params: { field, value },
    });
    return data;
  }

  getPublicTemplate = async (publicId: string) => {
    const { data } = await this.usersWorker.get(
      `/templates/${publicId}/public`,
    );
    return data;
  };

  getRoomInvitation = async ({ urlToken }: { urlToken: string }) => {
    const { data } = await this.usersWorker.get(
      '/sessions/scheduled/' + urlToken,
    );

    return data;
  };

  async getAvailabilityByDuration({
    date,
    duration,
  }: {
    date: string;
    duration: number;
  }) {
    const { data } = await this.usersWorker.get('/sessions/availability', {
      params: {
        date,
        duration,
      },
    });
    return data.availableDates.filter((date) => {
      return !isPast(new Date(date.startDate));
    });
  }

  toggleUsageBasedBilling = async ({ enable }: { enable: boolean }) => {
    const { data } = await this.usersWorker.post(
      '/toggle-usage-based-subscription',
      {
        enable,
      },
    );
    return data;
  };

  getApplicationsAdmin = async ({
    page_size,
    page,
  }: {
    page_size: number;
    page: number;
  }) => {
    const { data } = await this.usersWorker.get(
      '/applications/get-applications-admin',
      {
        params: {
          page_size,
          page,
        },
      },
    );
    return data;
  };

  sendRating = async ({
    rating,
    roomId,
  }: {
    rating: number;
    roomId: string;
  }) => {
    const { data } = await this.usersWorker.post('/rating', {
      rating,
      roomId,
    });

    return data;
  };

  getOrgMembers = async (companyId: string) => {
    const { data } = await this.usersWorker.get('/members', {
      params: {
        companyId,
      },
    });

    return data as IDocument<User>[];
  };

  getTemplates = async (visibilityFilter: any) => {
    const { data: sessionTemplates } = await this.usersWorker.get<Template[]>(
      '/templates',
      {
        params: {
          visibilityFilter: visibilityFilter.join(','),
        },
      },
    );

    return sessionTemplates;
  };

  inviteUserToCompany = (email: string, companyId: string) =>
    this.usersWorker.post('/user/invite-to-org', {
      email,
      companyId,
    });

  updateUser = async (userId: string, data: any) => {
    await this.usersWorker.put('/' + userId, data);
  };

  getCompany = async (): Promise<Company | null> => {
    const { data } = await this.usersWorker.get(`/companies/single`);
    return data;
  };

  getCompanyByBoardDomain = async (boardDomain: string): Promise<Company> => {
    const { data } = await this.usersWorker.get(`/companies`, {
      params: {
        boardDomain,
      },
    });

    return data;
  };

  inviteInfo = async (id: string): Promise<InviteInfo | null> => {
    const { data } = await this.usersWorker.get('/user/invite', {
      params: {
        urlToken: id,
      },
    });

    return data;
  };

  createUser = async (userId: string, data: any) => {
    await this.usersWorker.post('/', {
      userId,
      value: data,
    });
  };

  publishJob = async (templateId: string) => {
    await this.usersWorker.put(`/templates/${templateId}/publish`);
    return true;
  };

  updateTemplate = async (templateId: string, template: any) => {
    if (!template.description) {
      template.description = JSON.stringify({
        root: {
          children: [
            {
              children: [],
              direction: null,
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
            },
          ],
          direction: null,
          format: '',
          indent: 0,
          type: 'root',
          version: 1,
        },
      });
    }
    await this.usersWorker.put(`/templates/${templateId}`, template);

    return true;
  };

  createTemplate = async (template: any) => {
    const { data: newTemplate } = await this.usersWorker.post('/templates', {
      ...template,
    });

    return newTemplate as IDocument<Template>;
  };

  getTemplate = async (templateId: string): Promise<IDocument<Template>> => {
    const { data: newTemplate } = await this.usersWorker.get(
      `/templates/${templateId}`,
    );

    return newTemplate;
  };

  getMeeting = async ({ publicId }: { publicId: string }): Promise<Room> => {
    try {
      //# Rename this
      const { data } = await this.usersWorker.get<Room>(
        `/rooms/meeting/${publicId}`,
        {},
      );

      return data;
    } catch (e) {
      console.log(e);
      if (!e.response || !e.response.data) {
        throw new RoomServerError();
      }
      if (e.response && e.response.data.code === 'ROOM_NOT_FOUND') {
        throw new RoomNotFound(publicId);
      } else if (e.response && e.response.data.code === 'ROOM_EXPIRED') {
        throw new RoomExpired();
      }
      throw new RoomServerError();
    }
  };

  validatePublicCompany = async (value: any) => {
    const { data } = await this.usersWorker.get(
      '/companies/domain_check/' + value,
    );
    if (data.detail === 'domain-exists') {
      return false;
    }
    return true;
  };

  createAccount = async (email: string, password: string, data: any) => {
    // Firebase User entity creation for authorization
    const userCredentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );

    const userId = userCredentials.user.uid;
    await this.createUser(userId, data);
    return { userId };
  };

  createAccountByInvite = async (
    email: string,
    password: string,
    data: any,
    company: Company,
  ) => {
    const { userId } = await this.createAccount(email, password, {
      ...data,
      companyId: company._id,
    });
  };

  saveCompanyInfo = async ({ values }: { values: any }) => {
    await this.usersWorker.put('/companies/update', { values });
  };

  createTestTask = async ({
    sessionTemplateId,
  }: {
    sessionTemplateId: string;
  }) => {
    const doc = await this.usersWorker.post<{
      public_id: string;
    }>('/sessions/test-session', {
      session_template_id: sessionTemplateId,
    });
    return doc.data;
  };

  createCompanyWithUser = async (fields: any, userId: string) => {
    const { data } = await this.usersWorker.post('/users/with-company', {
      values: fields,
      userId,
    });
    return data.user;
  };
  async findActivePositions() {
    const { data } = await this.usersWorker.get<InterviewResult[]>(
      '/rooms/open-positions',
    );

    return data;
  }

  getApplicationComments = async (applicationId: string) => {
    const { data } = await this.usersWorker.get(
      `/applications/comments/${applicationId}`,
    );
    return data;
  };

  async findAllCandidates({
    page_size,
    page,
    session_template_id,
    status,
    email,
    stage,
    is_archived,
    normalized_only,
  }: {
    page_size: number;
    page: number;
    session_template_id: string;
    status: string;
    email: string;
    stage: string;
    is_archived: boolean;
    normalized_only: boolean;
  }) {
    const { data } = await this.usersWorker.get<GetAppsResponse>(
      '/rooms/candidates',
      {
        params: {
          page_size,
          page,
          session_template_id: session_template_id,
          status: status || undefined,
          email: email,
          stage: stage || undefined,
          normalized_only: normalized_only || false,
          is_archived: is_archived || false,
        },
      },
    );

    return data;
  }

  addApplicationComment = async (data: {
    application_id: string;
    comment: string;
  }) => {
    await this.usersWorker.post('/applications/add-comment', data);
  };

  archiveApplications = async (
    applicationIds: string[],
    is_archived: boolean,
  ) => {
    await this.usersWorker.post('/applications/archive', {
      application_ids_list: applicationIds,
      is_archived,
    });
  };

  updateApplicationStage = async (applicationIds: string[], stage: string) => {
    await this.usersWorker.post('/applications/update-stage', {
      application_ids_list: applicationIds,
      stage,
    });
  };

  findCandidateSummary = async (roomId: string) => {
    const { data } = await this.usersWorker.get(
      `/rooms/candidate-summary/${roomId}`,
    );
    return data;
  };

  generateCustomQuestions = async (
    templateId: string,
  ): Promise<
    {
      text: string;
      maximum_point: number;
    }[]
  > => {
    const { data } = await this.usersWorker.post(
      `/templates/${templateId}/generate-questions`,
    );
    return data.questions;
  };

  normalizeApps = async ({
    sessionTemplateId,
  }: {
    sessionTemplateId: string;
  }) => {
    const { data } = await this.usersWorker.post(
      '/normalize/' + sessionTemplateId,
    );
    return data;
  };

  validateEmail = async (email: string) => {
    const { data } = await this.usersWorker.post('/users/validate-email', {
      email,
    });
    return data;
  };

  generateSlide = async (roomId: string) => {
    const { data } = await this.usersWorker.post(
      `/rooms/${roomId}/generate-slide`,
    );
    return data;
  };

  leaveInterview = async (roomId: string) => {
    const { data } = await this.usersWorker.post(`/rooms/${roomId}/leave`);
    return data;
  };

  startRecording = async (roomId: string) => {
    const { data } = await this.usersWorker.post(
      `/rooms/${roomId}/start-recording`,
    );
    return data;
  };

  stopRecording = async (roomId: string) => {
    const { data } = await this.usersWorker.post(
      `/rooms/${roomId}/stop-recording`,
    );
    return data;
  };

  submitFeedback = async (roomId: string, feedback: any) => {
    const { data } = await this.usersWorker.post(
      `/rooms/${roomId}/feedback`,
      feedback,
    );
    return data;
  };

  searchRooms = async (query: string) => {
    const { data } = await this.usersWorker.get('/rooms/search', {
      params: { query },
    });
    return data;
  };

  // Assessment Templates
  getAssessmentTemplates = async () => {
    const { data } = await this.usersWorker.get('/assessment_templates');
    return data;
  };

  createAssessmentTemplate = async (template: any) => {
    const { data } = await this.usersWorker.post(
      '/assessment_templates/',
      template,
    );
    return data;
  };

  updateAssessmentTemplate = async (templateId: string, template: any) => {
    const { data } = await this.usersWorker.put(
      `/assessment_templates/${templateId}`,
      template,
    );
    return data;
  };

  deleteAssessmentTemplate = async (templateId: string) => {
    await this.usersWorker.delete(`/assessment_templates/${templateId}`);
  };

  // Assessment Blocks
  getAssessmentBlocks = async () => {
    const { data } = await this.usersWorker.get('/assessment_blocks/');
    return data;
  };

  createAssessmentBlock = async (block: any) => {
    const { data } = await this.usersWorker.post('/assessment_blocks/', block);
    return data;
  };

  updateAssessmentBlock = async (blockId: string, block: any) => {
    const { data } = await this.usersWorker.put(
      `/assessment_blocks/${blockId}`,
      block,
    );
    return data;
  };

  deleteAssessmentBlock = async (blockId: string) => {
    await this.usersWorker.delete(`/assessment_blocks/${blockId}`);
  };
}

export default new MongoClient();
