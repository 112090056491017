import React, { useEffect } from 'react';
import {
  PageContextProvider,
  usePageContext,
} from 'greet-button/src/hooks/usePageContext';
import type { PageContext } from './types';
import { RecoilRoot } from 'recoil';
import { queryClient } from '@/api/queryClient';
import { roomEndedState, roomErrorState, room_SSR_data } from '@/state/atoms';
import {
  MantineProvider,
  createTheme,
  MantineColorsTuple,
} from '@mantine/core';
// _app.jsx
import { QueryClientProvider } from 'react-query';
import AuthLayer from '@/AuthLayer';
import { Helmet } from 'react-helmet-async';
import { tags } from '@/utils/misc';

export { PageShell };

const all_atoms = {
  roomErrorState: roomErrorState,
  room_SSR_data: room_SSR_data,
  roomEndedState: roomEndedState,
};

const initializeRecoilState =
  (initialRecoilState) =>
  ({ set }) =>
    Object.keys(initialRecoilState).map((key) => {
      const value = initialRecoilState[key];
      const atom = all_atoms[key];
      set(atom, value);
    });

function PageShell({
  children,
  pageContext,
}: {
  children: React.ReactNode;
  pageContext: PageContext;
}) {
  const { urlPathname } = pageContext;
  const child =
    urlPathname.includes('/meeting') ||
    urlPathname.includes('/apply') ||
    urlPathname.includes('/share') ||
    urlPathname.includes('/csae') ||
    urlPathname.includes('/appdemo') ||
    urlPathname.includes('/jot/') ||
    urlPathname.includes('/recording-view') ? (
      children
    ) : (
      <AuthLayer>{children}</AuthLayer>
    );
  return (
    <React.StrictMode>
      <PageContextProvider pageContext={pageContext}>
        <Layout>{child}</Layout>
      </PageContextProvider>
    </React.StrictMode>
  );
}

const myColor: MantineColorsTuple = [
  '#f6fee7',
  '#edfbd3',
  '#daf7a5',
  '#c5f375',
  '#b4ee4c',
  '#a9ec34',
  '#a4ec26',
  '#8ed11a',
  '#7dba10',
  '#69a100',
];

const theme = createTheme({
  autoContrast: true,
  colors: {
    'ocean-blue': myColor,
  },
});

function Layout({ children }: { children: React.ReactNode }) {
  const { urlPathname, ...rest } = usePageContext();
  const initialState = {
    roomErrorState: null,
    room_SSR_data: rest.pageProps?.room,
    roomEndedState: rest.pageProps?.room?.state === 'last-stage-finished',
  };

  if (rest.pageProps?.roomError) {
    initialState.roomErrorState = rest.pageProps.roomError;
  }
  const { title, description, keywords } = tags;

  return (
    <RecoilRoot initializeState={initializeRecoilState(initialState)}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider forceColorScheme={'dark'} theme={theme}>
          <Helmet>
            <title>{title}</title>
            <link rel="canonical" href="https://www.app.greetai.co/" />
            <meta name="description" content={description}></meta>
            <meta name="application-name" content="GreetAI"></meta>
            <meta name="keywords" content={keywords}></meta>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"></meta>
            <meta
              name="robots"
              content="index, follow, max-video-preview:-1, max-image-preview:large, max-snippet:-1"></meta>
            <meta name="googlebot" content="index, follow"></meta>
            <meta name="apple-mobile-web-app-capable" content="yes"></meta>
            <meta name="apple-mobile-web-app-title" content="GreetAI"></meta>
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="default"></meta>
            <meta property="og:title" content={title}></meta>
            <meta property="og:description" content={description}></meta>
            <meta property="og:url" content="https://greetai.co/"></meta>
            <meta property="og:image:type" content="image/jpeg"></meta>
            <meta property="og:image:height" content="627"></meta>
            <meta property="og:image:width" content="1200"></meta>
            <meta
              property="og:image"
              content="https://greetai.co/opengraph-image.jpg?8b3ae05368d8723e"></meta>
            <meta property="og:type" content="website"></meta>
            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@Greet_AI"></meta>
            <meta name="twitter:creator" content="@Greet_AI"></meta>
            <meta name="twitter:title" content={title}></meta>
            <meta
              name="twitter:description"
              content="greetai.co- Practice job interviews with AI and get instant feedback"></meta>
            <meta name="twitter:image:type" content="image/jpeg"></meta>
            <meta name="twitter:image:width" content="1200"></meta>
            <meta name="twitter:image:height" content="627"></meta>
            <meta
              name="twitter:image"
              content="https://greetai.co/twitter-image.jpg?8b3ae05368d8723e"></meta>
          </Helmet>
          {children}
        </MantineProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
}
